export interface DepositRate {
  term: {
    type: 'months' | 'days'; // Same
    value: number | string;
  };
  minimumDeposit: {
    value: number;
  };
  maturity: {
    value: number;
  };
  annually?: {
    value: number;
  };
  semiAnnually?: {
    value: number;
  };
  monthly?: {
    value: number;
  };
}

// Follow the format - MM/DD/YYYY
export const effectiveDate = new Date('10/08/2024');

export const depositRates: DepositRate[] = [
  {
    term: {
      type: 'months',
      value: 12,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.86,
    },
    annually: {
      value: 3.86,
    },
    semiAnnually: {
      value: 3.76,
    },
    monthly: {
      value: 3.66,
    },
  },
  {
    term: {
      type: 'months',
      value: 24,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.57,
    },
    annually: {
      value: 3.57,
    },
    semiAnnually: {
      value: 3.47,
    },
    monthly: {
      value: 3.37,
    },
  },
  {
    term: {
      type: 'months',
      value: 36,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.56,
    },
    annually: {
      value: 3.56,
    },
    semiAnnually: {
      value: 3.46,
    },
    monthly: {
      value: 3.36,
    },
  },
  {
    term: {
      type: 'months',
      value: 48,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.52,
    },
    annually: {
      value: 3.52,
    },
    semiAnnually: {
      value: 3.42,
    },
    monthly: {
      value: 3.32,
    },
  },
  {
    term: {
      type: 'months',
      value: 60,
    },
    minimumDeposit: {
      value: 5000,
    },
    maturity: {
      value: 3.52,
    },
    annually: {
      value: 3.52,
    },
    semiAnnually: {
      value: 3.42,
    },
    monthly: {
      value: 3.32,
    },
  },
  {
    term: {
      type: 'days',
      value: '30-59',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 0.1,
    },
  },
  {
    term: {
      type: 'days',
      value: '60-89',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 0.15,
    },
  },
  {
    term: {
      type: 'days',
      value: '90-179',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 1.1,
    },
  },
  {
    term: {
      type: 'days',
      value: '180-269',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 2.01,
    },
  },
  {
    term: {
      type: 'days',
      value: '270-364',
    },
    minimumDeposit: {
      value: 10000,
    },
    maturity: {
      value: 2.36,
    },
  },
];
